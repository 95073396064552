.card {
    padding: 24px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    background-color: #1C1C21;
    border-radius: 40px;
    width: 100%;
    text-decoration: none;
    color: #fff;
}

.card:visited {
    color: #fff;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.status {
    margin-top: -16px;
}

.statusDone {
    color: #00CCCC;
}

.info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
}

.ul {
    display: flex;
}

.price {
    display: flex;
    gap: 8px;
}
