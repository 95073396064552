.scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: auto;
    scrollbar-color: #8585AD #2F2F37;
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
}

.scroll::-webkit-scrollbar {
    width: 8px;
}

.scroll::-webkit-scrollbar-track {
    background-color: #2F2F37;
}

.scroll::-webkit-scrollbar-thumb  {
    background-color: #8585AD;
}
