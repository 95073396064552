:root {
    --error-icon-bg:radial-gradient(50% 50% at 50% 50%,
                    rgba(251, 44, 86, 0) 0%,
                    rgba(251, 44, 86, 0.45) 100%);
}

.icon {
    width: 150px;
    height: 150px;
    position: relative;
}

.circle {
    background: var(--error-icon-bg);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
}

.circle:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    background: var(--error-icon-bg);
    width: 90%;
    height: 90%;
    border-radius: inherit;
}

.circle:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    background: var(--error-icon-bg);
    width: 70%;
    height: 70%;
    border-radius: inherit;
}

.sign {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
