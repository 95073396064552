.section {
    /*padding-top: 40px;*/
    padding: 40px 5vw 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.flex {
    margin-top: 20px;
    /*display: grid;*/
    /*grid-template-columns: repeat(2, 1fr);*/
    /*grid-column-gap: 60px;*/
    width: 100%;
    max-height: 100%;
}

.ul {
    padding-right: 8px;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
