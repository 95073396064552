.ingredient {
    margin-left: -20px;
    list-style: none;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    position: relative;
    /*border-image: linear-gradient(rgba(128, 26, 178, 1), rgba(76, 76, 255, 1));*/
}

.ingredient:first-child {
    margin: 0;
}

.ingredient:nth-child(6)::after {
    visibility: visible;
}

.ingredient::before {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(45deg, rgba(128, 26, 178, 1) 0%, rgba(76, 76, 255, 1) 100%);
    position: absolute;
}

.ingredient::after {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    background-color: #1C1C21;
    opacity: .6;
    visibility: hidden;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 15%;
    border-radius: 50%;
    background-clip: padding-box;
    border: solid 3px transparent;
    position: absolute;
    background-color: #131316;
    top: 0;
    left: 0;
}

.count {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}
