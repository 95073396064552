.ingredients {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.ul {
    max-height: 95%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    /*padding-bottom: 220px;*/
}

.flex {
    display: flex;
}
