@import url('./vendor/normalize.css');
@import url('./vendor/scroll.css');
@import url('./vendor/no-select.css');
@import url('./vendor/visible.css');

:root {
  --modal-bg: #1C1C21;

}

body,
body::after,
body::before {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /*outline: 1px red solid;*/
}

#root {
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding: 0 2vw;
}

.main {
  height: 90vh;
  display: flex;
  justify-content: center;
  /*margin: 0 12vw;*/
}


.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.loading-spinner-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;

}

.digitsShadowGlow {
  text-shadow: 0 0 16px rgba(51, 51, 255, 0.25),
  0 0 8px rgba(51, 51, 255, 0.25),
  0 4px 32px rgba(51, 51, 255, 0.5);
}
