.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 180px;
}

.formProfile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.linkText {
    text-decoration: none;
    color: #4C4CFF;
}

