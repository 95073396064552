.profile {
    margin-top: 120px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 60px;
    width: 100%;
}
.nav {
    padding-left: 2vw;
    display: flex;
    flex-direction: column;
    max-width: 320px;
}

.link {
    display: flex;
    align-items: center;
    width: 320px;
    height: 64px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
}


.linkActive {
    color: #fff;
    text-decoration: none;
}

