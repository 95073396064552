.section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 5vw;
    padding: 0 10vw;
}

.h2 {
    font-size: 30rem;
    text-shadow: 0 0 16px rgba(51, 51, 255, .25),
                 0 0 8px rgba(51, 51, 255, .25),
                 0 4px 32px rgba(51, 51, 255, .5);
}

.article {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5vh;
}

.button {
    max-width: 80%;
}
