.h3 {
    text-align: center
}

.ul {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-bottom: 20px;
}

.value {
    display: flex;
    flex-direction: column;
    align-items: center;
}
