.element{
    height: 100%;
}

.ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 80%;
}

.scroll {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    /*min-height: 500px;*/
}

.info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.price {
    display: flex;
    gap: 8px;
    align-items: center;
}

.noIngredient {
    width: 33%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
