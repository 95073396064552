.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--modal-bg);
    padding: 40px;
    z-index: 9999;
    min-width: 450px;
    min-height: 450px;
    border: 1px solid rgba(76, 76, 255, 0.2);
    box-shadow: 0 24px 32px rgba(0, 0, 0, 0.04),
                0 16px 24px rgba(0, 0, 0, 0.04),
                0 4px 8px rgba(0, 0, 0, 0.04),
                0 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 40px;
}

.top {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.button {
    margin-left: 40px;
    background-color: transparent;
    border: none;
    width: 45px;
    height: 45px;
    color: #fff;
    cursor: pointer;
    z-index: 9999;
    font-size: 36px;
}
