.overlay {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 60%);
    width: 100vw;
    height: 100vh;
    z-index: 999;
    overflow: hidden;
}
